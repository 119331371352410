/* eslint-disable react/jsx-no-bind */
import React, { useMemo, useEffect, useCallback, Fragment, useState } from 'react'
import { Typography, Grid } from '@material-ui/core'
import { useLocation, useParams, redirectTo, Redirect } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { Preview, Button, Toast, Alert } from '@refera/ui-web'
import { Attachments } from '_/components/refera-ui'

import Header, { HEADER_THEME } from '_components/header'
import Footer from '_components/footer'
import Svg from '_components/svg'
import Loading from '_components/loading'
import CallDetails from '_assets/svgs/detalhes-do-chamado.svg'
import UserIcon from '_assets/svgs/user-icon.svg'
import AddressIcon from '_assets/svgs/address-icon.svg'
import { getServiceOrderByID } from '_modules/service-orders/selectors'
import { getServiceOrder } from '_modules/service-orders/actions'
import { getBudgetByIdSelector } from '_modules/budget/selectors'
import { AUTHENTICATE_TOKEN, authenticateToken } from '_modules/authentication/actions'
import useFetchCall from '_hooks/use-fetch-call'
import { formatDate } from '_/utils/helpers'

import useStyles from './styles'
import classnames from 'classnames'
import { userSelector } from '_/modules/authentication/selectors'

const defaultPreviewState = {
  visible: false,
  selectedIndex: undefined,
}

const toPercentage = value => Number(value) / 100

const PostWork = () => {
  const styles = useStyles()
  const dispatch = useDispatch()

  const { serviceOrderId } = useParams()

  const user = useSelector(userSelector)
  const { search } = useLocation()
  const verificationToken = search.split('verification_token=')[1]
  const serviceOrder = useSelector(state => getServiceOrderByID(state, serviceOrderId))
  const budget = useSelector(getBudgetByIdSelector(serviceOrder?.get('activeBudget')))
  const [previewState, setPreviewState] = useState(defaultPreviewState)
  const [isServiceOrderLoading, setIsServiceOrderLoading] = useState(true)
  const [toast, setToast] = useState({ isOpen: false, message: '', type: '' })

  const applyPercentages = useCallback(
    value => {
      if (budget) {
        const {
          financialIncome,
          agencyCompletionPercentage: agencyPercentage,
          referaCompletionPercentage: referaPercentage,
        } = budget

        const partialValue =
          value + (toPercentage(agencyPercentage) + toPercentage(referaPercentage)) * value

        return partialValue * toPercentage(financialIncome) + partialValue
      }
      return null
    },
    [budget]
  )

  function CopyLinkToPostWorkPage() {
    const currentLink = window.location.href
    if (serviceOrder?.payerVerificationToken) {
      navigator.clipboard.writeText(
        `${currentLink}/?verification_token=${serviceOrder?.payerVerificationToken}`
      )
      setToast({
        isOpen: true,
        message: 'Conteúdo copiado para a área de transferência do dispositivo.',
        type: 'success',
      })
    }
  }

  const loadBudget = useCallback(async () => {
    if (verificationToken && !user?.id) {
      return dispatch(authenticateToken({ verificationToken })).then(() => {
        dispatch(getServiceOrder(serviceOrderId))
          .then(() => {
            setIsServiceOrderLoading(false)
          })
          .catch(() => {
            return <Redirect to="/nao-encontrado" noThrow />
          })
      })
    }

    return dispatch(getServiceOrder(serviceOrderId))
      .then(() => {
        setIsServiceOrderLoading(false)
      })
      .catch(() => {
        return <Redirect to="/nao-encontrado" noThrow />
      })
  }, [dispatch, verificationToken, serviceOrder, serviceOrderId])

  useEffect(() => {
    loadBudget()
  }, [])

  const onAuthenticateTokenFailed = useCallback(() => {
    redirectTo('/link-expirado')
  }, [])

  useFetchCall(AUTHENTICATE_TOKEN.ACTION, () => {}, onAuthenticateTokenFailed)

  const approbationDate = useMemo(() => {
    if (serviceOrder?.get('closedAt')) return formatDate(serviceOrder?.get('closedAt'))
    return ''
  }, [serviceOrder?.get('closedAt')])

  const formatAddress = useMemo(() => {
    const property = serviceOrder?.get('property')?.toJS()

    return `${property?.address ? `${property?.address}, ` : ''} ${
      property?.number ? property?.number : ''
    } - ${property?.neighborhood ? `${property?.neighborhood}` : ''}${
      property?.city ? `, ${property?.city}` : ''
    } ${property?.uf ? `, ${property?.uf}` : ''}`
  }, [serviceOrder])

  const renderProductList = useMemo(() => {
    const list = budget?.get('budgetItems')?.toJS()
    const total = applyPercentages(
      list?.reduce((result, currentValue) => result + currentValue.price, 0)
    )

    return (
      <Grid className={styles.productContainer}>
        <Typography className={styles.firstRow}>Descrição</Typography>
        <Typography className={styles.firstRow}>R$</Typography>
        {list?.map(product => {
          return (
            <Fragment key={product?.description}>
              <Typography className={styles.product}>{product?.description}</Typography>
              <Typography className={styles.product}>
                {applyPercentages(parseFloat(product?.price)).toFixed(2)}
              </Typography>
            </Fragment>
          )
        })}
        <Grid container className={styles.totalContainer}>
          <Typography className={styles.totalLabel}>Total</Typography>
          <Typography className={styles.total}>{total?.toFixed(2)}</Typography>
        </Grid>
      </Grid>
    )
  }, [budget])

  const attachments = useMemo(() => {
    const postWorkPicturesList =
      serviceOrder
        ?.get('attachments')
        ?.toJS()
        ?.filter(file => file?.fileType === 'execution_proof')
        ?.map(file => ({ file: file?.file, id: file?.id })) || []

    const invoiceList =
      serviceOrder
        ?.get('attachments')
        ?.toJS()
        ?.filter(file => file?.fileType === 'invoice')
        ?.map(file => ({ file: file?.file, id: file?.id })) || []

    return { postWorkPicturesList, invoiceList }
  }, [serviceOrder])

  const PreviewComponent = useMemo(
    () => (
      <Preview
        open={previewState.visible}
        onClose={() => setPreviewState(defaultPreviewState)}
        selectedIndex={previewState.selectedIndex}
        images={attachments.postWorkPicturesList?.map((item, index) =>
          item.id
            ? {
                id: item.id,
                url: item.file,
              }
            : {
                id: index,
                url: URL.createObjectURL(item),
              }
        )}
      />
    ),
    [previewState, attachments]
  )

  const handleItemClick = useCallback(index => {
    setPreviewState(() => ({
      visible: true,
      selectedIndex: index,
    }))
  }, [])

  const handleCloseToast = () => {
    setToast(prevState => ({ ...prevState, isOpen: false }))
  }

  if (isServiceOrderLoading || !serviceOrder) {
    return <Loading />
  }

  return (
    <Grid className={styles.container}>
      {PreviewComponent}
      <Header theme={HEADER_THEME.NO_BUTTONS} className={styles.header} />
      <Grid className={styles.introductionContainer}>
        <Svg icon={CallDetails} className={classnames(styles.image, styles.fullRow)} />
        <Typography className={classnames(styles.title, styles.fullRow)}>
          Seu serviço foi finalizado pela Refera ;)
        </Typography>
        {approbationDate && (
          <Typography className={classnames(styles.dateTime, styles.fullRow)}>
            {approbationDate}
          </Typography>
        )}

        <Grid className={classnames(styles.card, styles.leftColumn)}>
          <Svg icon={UserIcon} className={styles.userIcon} />
          <Typography className={styles.cardTitle}>Dados do prestador</Typography>
          <Typography className={styles.cardContent}>
            {budget?.get('tradesman')?.toJS()?.companyName}
          </Typography>
        </Grid>

        <Grid className={classnames(styles.card, styles.rightColumn)}>
          <Svg icon={AddressIcon} className={styles.userIcon} />
          <Typography className={styles.cardTitle}>Endereço do imóvel</Typography>
          <Typography className={styles.cardContent}>{formatAddress}</Typography>
        </Grid>

        <Grid className={classnames(styles.section, styles.fullRow)}>
          <Typography className={styles.cardTitle}>Descrição do chamado</Typography>
          <Typography className={styles.sectionContent}>
            {serviceOrder?.get('description')}
          </Typography>
        </Grid>

        <Typography className={classnames(styles.title, styles.fullRow, styles.budget)}>
          Orçamento
        </Typography>

        <Grid className={classnames(styles.section, styles.fullRow)}>
          <Typography className={styles.cardTitle}>Diagnóstico do problema</Typography>
          <Typography className={styles.sectionContent}>{budget?.get('diagnosis')}</Typography>
        </Grid>

        <Grid className={classnames(styles.section, styles.fullRow)}>
          <Typography className={styles.cardTitle}>Solução do problema</Typography>
          <Typography className={styles.sectionContent}>
            {budget?.get('proposedSolution')}
          </Typography>
        </Grid>

        <Grid className={classnames(styles.section, styles.fullRow)}>
          <Typography className={styles.cardTitle}>Orçamento detalhado</Typography>
          {renderProductList}
        </Grid>

        <Grid className={classnames(styles.section, styles.fullRow)}>
          <Typography className={styles.cardTitle}>Anexos</Typography>
          <Grid className={styles.postWorkAttach}>
            <Attachments
              label="Fotos do pós-obra"
              readOnly
              files={attachments.postWorkPicturesList}
              downloadable
              onItemClick={handleItemClick}
              accept={{
                'image/jpeg': [
                  '.jpeg',
                  '.png',
                  '.jpg',
                  '.bmp',
                  '.webp',
                  '.mkv',
                  '.mp4',
                  '.mov',
                  '.avi',
                  '.m4v',
                  '.mpg',
                  '.mpeg',
                  '.wmv',
                  '.webm',
                ],
              }}
            />
          </Grid>
          <Grid className={styles.postWorkAttach}>
            <Attachments
              label="Nota fiscal"
              readOnly
              files={attachments.invoiceList}
              content="files"
              downloadable
              onItemClick={index => window.open(attachments.invoiceList[index].file)}
              accept={{
                'application/pdf': ['.pdf'],
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Button onClick={CopyLinkToPostWorkPage} color="primary" variant="primary">
        Copiar link da tela
      </Button>
      <Toast draggable open={toast.isOpen} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert severity={toast.type} title={toast.message} onClose={handleCloseToast} />
      </Toast>
      <Footer className={styles.footer} />
    </Grid>
  )
}

export default PostWork
