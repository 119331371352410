import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Typography } from '@refera/ui-web'
import { Message, ArrowLeft as ArrowLeftIcon } from '@refera/ui-icons'
import Theme from '@refera/ui-core'
import { Grid } from '@material-ui/core'
import { getUserLoadingSelector } from '_modules/authentication/selectors'
import { getMessaging } from '_modules/mensageria/actions'
import { ROUTES } from '_utils/constants'
import { navigate } from '@reach/router'
import { MessageMainTable } from '_components/mensageria'
import Loading from '_components/loading'
import IconButton from '_/components/svg/icon-button'

import useStyles from './styles'
import { useToast } from '_/hooks/use-toast'

const Mensageria = () => {
  const styles = useStyles()
  const loading = useSelector(getUserLoadingSelector)
  const { showToast } = useToast()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getMessaging())
      .then(() => {})
      .catch(err =>
        showToast({ type: 'error', message: err?.error_message ?? 'Ocorreu um erro desconhecido' })
      )
  }, [dispatch])

  const handleRowClick = useCallback(() => {
    navigate(`${ROUTES.MENSAGERIA}/novo`, 'noopener,noreferrer')
  }, [])

  if (loading) {
    return <Loading />
  }

  return (
    <Grid className={styles.container}>
      <Grid className={styles.header}>
        <Grid className={styles.wrapper}>
          <IconButton aria-label="Voltar página" onClick={() => navigate(-1)}>
            <ArrowLeftIcon color="black" />
          </IconButton>
          <Typography variant="h4" component="h1" className={styles.title}>
            Mensageria da plataforma
          </Typography>
        </Grid>
        <Grid className={styles.buttonHeader}>
          <Button
            variant="ghost"
            startIcon={<Message color={Theme.Colors.Primary.Base} />}
            onClick={handleRowClick}
          >
            Nova Mensagem
          </Button>
        </Grid>
      </Grid>
      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <MessageMainTable />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Mensageria
