import { makeStyles } from '@material-ui/styles'

export default makeStyles(({ spacing, palette }) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  introductionContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginTop: spacing(48),
    gap: 16,
  },
  fullRow: {
    gridColumn: '1 / 3',
    justifySelf: 'center',
  },
  leftColumn: {
    justifySelf: 'end',
  },
  rightColumn: {
    justifySelf: 'start',
  },
  card: {
    width: 368,
    padding: 16,
    marginTop: spacing(24),
    border: `1px solid ${palette.gray[12]}`,
    borderRadius: 8,
    display: 'grid',
    gridTemplateColumns: '60px auto',
    alignItems: 'center',
  },
  cardTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: palette.gray[80],
    fontFamily: 'DM Sans, sans-serif',
  },
  cardContent: {
    color: palette.text.secondary,
    gridColumn: '2 / 3',
    alignSelf: 'start',
  },
  image: {
    width: 60,
    height: 60,
    marginTop: spacing(32),
  },
  title: {
    marginTop: spacing(16),
    fontSize: 24,
    color: palette.primary.main,
    fontWeight: 500,
  },
  dateTime: {
    marginTop: spacing(11),
    color: palette.text.secondary,
  },
  userIcon: {
    width: 32,
    height: 32,
  },
  section: {
    width: 752,
    marginTop: 24,
  },
  sectionContent: {
    padding: 16,
    marginTop: 12,
    border: `1px solid ${palette.gray[12]}`,
    borderRadius: 8,
    color: palette.text.secondary,
  },
  budget: {
    marginTop: 24,
  },
  productContainer: {
    display: 'grid',
    gridTemplateColumns: '560px 1fr',
    rowGap: 8,
    columnGap: 16,
    color: palette.gray[80],
  },
  firstRow: {
    marginTop: 16,
    fontSize: 16,
  },
  product: {
    padding: '10px 7px',
    border: `1px solid ${palette.gray[12]}`,
    borderRadius: 8,
  },
  totalContainer: {
    marginTop: spacing(16),
    gridColumn: '2 / 3',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  totalLabel: {
    fontSize: 16,
    fontWeight: 500,
    color: palette.text.secondary,
  },
  total: {
    fontSize: 24,
    color: palette.primary.main,
    fontWeight: 500,
  },
  postWorkAttach: {
    marginTop: spacing(24),
  },
  footer: {
    marginTop: spacing(24),
    width: '100%',
  },
}))
