import {
  PAGES_NAME,
  PROPERTY_USER_INFO_FIELDS,
  CONTACT_FIELDS,
  ADDRESS_FIELDS,
  PROPERTY_FIELDS,
} from '../../constants'

export const fieldsToChangeByPage = {
  [PAGES_NAME.OWNER_DETAILS]: Object.values(PROPERTY_USER_INFO_FIELDS),
  [PAGES_NAME.REQUEST_CONTACT]: Object.values(CONTACT_FIELDS).filter(
    field => field !== CONTACT_FIELDS.CONTACT_NUMBER_CONFIRMATION
  ),
  [PAGES_NAME.PROPERTY]: Object.values(ADDRESS_FIELDS).filter(
    field => field !== ADDRESS_FIELDS.CEP
  ),
  [PAGES_NAME.PROPERTY_DETAILS]: Object.values(PROPERTY_FIELDS).filter(
    field => field !== PROPERTY_FIELDS.PROPERTY_LOCATION
  ),
}
